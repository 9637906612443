import React, { useState, useEffect, useRef } from 'react'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { format } from 'date-fns'
import Textarea from 'react-textarea-autosize'
import { isEmail } from '@drapp/helpers'
import ReactTooltip from 'react-tooltip'

import './App.scss'

let params = {}
const { hash } = window.location

try {
  if (hash !== '#') {
    params = JSON.parse(atob(hash.replace(/^#/, '')))
  }
} catch (e) {
  params = {}
}

/*params = {
  patient: {
    name: 'Kleine Samson, Ricardo',
    email: 'ricardokleinesamson@gmail.com',
    identification: '47653994',
  },
  practitioner: {
    prefix: 'Dr.',
    name: 'Ricardo E. Barcia',
    specialty: 'Clinica Medica',
    address: 'JUNIN 1191, 2 PISO - CABA',
    phone: '4821-0967. Opción 2',
    email: 'ricardo@drapp.com.ar'
  },
  insurance: {
    name: 'PAMI PAVO',
    plan: '310',
    id: '701118',
  },
  member: {
    // barcia
    // type: 'M.N.',
    // number: '74463',
    // barcia
    type: 'M.P.',
    number: '37769',
    state: 'buenos-aires',
    // chang
    // type: 'M.N.',
    // number: '11093',
    // state: 'buenos-aires',

  },
  highlight: true,
  content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.\n\nAspernatur sapiente commodi laudantium, placeat reprehenderit numquam delectus. Cum sunt sit quis provident beatae velit totam, consectetur assumenda fuga accusantium illo error!",
  selected: [
    {
      "id": "47066",
      "label": "Atorvastatin - KOSPIDEL 20 - 20 mg comp.rec.x 30",
      "drugCode": "01131",
      "quantity": "1",
      "items": [
        "Atorvastatin",
        "KOSPIDEL 20",
        "20 mg comp.rec.x 30"
      ]
    },
    {
      "id": "51033",
      "label": "Ibuprofeno + Clorzoxazona - IBUMEJORAL FLEX 600 - 600 mg comp.x 20",
      "drugCode": "00578",
      "quantity": "1",
      "items": [
        "Ibuprofeno + Clorzoxazona",
        "IBUMEJORAL FLEX 600",
        "600 mg comp.x 20"
      ]
    },
    {
      "id": "47816",
      "label": "Atorvastatin + Ezetimibe - ZARATOR PLUS - 10/10mg comp.x30",
      "drugCode": "10307",
      "quantity": "1",
      "items": [
        "Atorvastatin + Ezetimibe",
        "ZARATOR PLUS",
        "10/10mg comp.x30"
      ]
    }
  ],
}
*/
const {
  REACT_APP_API_URL
} = process.env

function App() {
  const $search = useRef(null)
  const storeKey = [params.database, params.practitionerID].filter(Boolean).join('/') || 'default'

  const [disabled, setDisabled] = useState(true)
  const [link, setLink] = useState()

  const [patient, setPatient] = useState(params.patient || {
    name: '',
    email: '',
    identification: ''
  })

  const [content, setContent] = useState(params.content || '')
  const [indications, setIndications] = useState(params.indications || '')
  const [certificate, setCertificate] = useState({})

  const [loading, setLoading] = useState(false)

  const [step, setStep] = useState(1)

  const [insurance, setInsurance] = useState(params.insurance || {
    name: '',
    plan: '',
    id: '',
  })

  const [practitioner, setPractitioner] = useState(params.practitioner || {
    name: '',
    specialty: '',
    prefix: 'Dra.',
    address: '',
    phone: '',
    email: '',
  })

  const [member, setMember] = useState(params.member || {
    number: '',
    type: 'M.N.',
    state: '',
  })

  const [date, setDate] = useState(params.date || format(new Date(), 'yyyy-MM-dd'))

  const [selected, setSelected] = useState(params.selected || [])
  const [options, setOptions] = useState([])
  const [checkSignature, setCheckSignature] = useState(false)

  const highlight = !!params.highlight
  const tracked = {}

  function getLink() {
    const link = JSON.stringify({
      patient,
      practitioner,
      insurance,
      member,
      highlight,
      content,
      indications,
      selected,
      certificate,
    })
    return `${window.location.origin}/#${btoa(link)}`
  }
  window.getLink = getLink

  function track (action, transactionID) {
    if (!window.gtag || !action) return
    const payload = {
      event_category: 'estudio',
      event_label: practitioner.name,
      value: selected.length,
      insurance: insurance.name,
      practitionerID: [params.database, params.practitionerID].filter(p => p).join('/'),
      transactionID,
    }
    if (selected.length > 0) {
      payload.event_category = 'receta'
      if (transactionID && !tracked[transactionID]) {
        tracked[transactionID] = true
        window.gtag('event', 'purchase', {
          transaction_id: transactionID,
          checkout_option: insurance.name,
          transactionID,
          insurance: insurance.name,
          practitionerID: [params.database, params.practitionerID].filter(p => p).join('/'),
          license_number: member.number || '',
          license_type: member.type || '',
          license_state: member.state || '',
          items: selected.map((item, index) => ({
            id: item.drugCode.toString(),
            name: item.label,
            quantity: item.quantity || '1',
            list_position: (index + 1),
            variant: item.id.toString()
          }))
        })
      }
    }
    window.gtag('event', action, JSON.parse(JSON.stringify(payload)))
  }

  async function post(type) {
    const payload = {
      practitioner,
      patient,
      insurance,
      member,
      selected,
      content,
      indications,
      date,
      certificate: {
        base64: certificate.base64,
        phrase: certificate.phrase,
      },
      database: params.database,
      practitionerID: params.practitionerID
    }

    let endpoint = REACT_APP_API_URL

    if (/drapp\.la$/.test(window.location.hostname || window.location.host)) {
      endpoint = REACT_APP_API_URL.replace('.com.ar', '.la')
    }

    const { link, id } = await fetch(`${endpoint}/${type}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(res => res.json())
      .catch(console.log) || {}

    track(type, id)

    return link
  }

  async function download() {
    if (loading) return
    setLoading(true)
    const link = await post('download')
    setLink(link)
    setLoading(false)

    const $a = document.createElement('a')
    $a.href = link
    $a.target = '_blank'
    $a.download = 'receta.pdf'
    $a.click()
  }

  async function sendEmail() {
    if (loading) return
    setLoading(true)
    await post('email')
    setLoading(false)
    alert('La receta fue enviada al email')
  }

  function remove(item) {
    const position = selected.findIndex(p => p.id === item.id)
    setSelected(selected.filter(i => i.label !== item.label))
    if (!window.gtag || position < 0) return
    window.gtag('event', 'remove_from_cart', {
      items: [
        {
          id: item.drugCode.toString(),
          name: item.label,
          quantity: item.quantity || '1',
          list_position: position,
          variant: item.id.toString()
        }
      ]
    })
  }

  function updateQ(item, quantity) {
    const items = selected.map(s => s.label === item.label ? { ...s, quantity } : s)
    setSelected(items)
  }

  const toggleType = item => {
    const items = selected.map(s => {
      if (s.label !== item.label) return s
      return {
        ...s,
        type: s.type === 'long-term' ? '' : 'long-term'
      }
    })
    setSelected(items)
  }

  const onChangeCertificate = async ({ target: { name, value, type, files } }) => {
    let payload = { ...certificate }
    if (type === 'file' && files[0]) {
      const base64 = await new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsDataURL(files[0])
        reader.onload = () => resolve(reader.result.replace('data:application/x-pkcs12;base64,',''))
      })
      payload[name] = files
      payload.name = files[0].name
      payload.base64 = base64
    } else if (type === 'checkbox') {
      payload[name] = !payload[name]
    } else {
      payload[name] = value
    }

    if (!payload[name]) {
      payload = {}
    }

    setCertificate({ ...payload })
    localStorage.setItem(storeKey, JSON.stringify({
      certificate: {
        ...payload,
        file: undefined,
        phrase: ''
      }
    }))
  }

  useEffect(() => {
    let disabled = !patient.name || !patient.identification
    disabled = disabled || !practitioner.name || !practitioner.specialty || !practitioner.prefix  || !practitioner.address  || !practitioner.phone || !practitioner.email
    disabled = disabled || !member.number || !member.type
    disabled = disabled || !date
    disabled = disabled || (!selected[0] && !content)
    setDisabled(disabled)
    setLink()
  }, [patient, practitioner, member, date, selected, content])

  useEffect(() => {
    try {
      const store = JSON.parse(localStorage.getItem(storeKey))
      if (store && store.certificate) setCertificate(store.certificate)
    } catch {}
  }, [storeKey])

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  return (
    <div className="App p-2">
      <ReactTooltip />
      <div className="p-3 p-md-4 p-lg-4 bg-white rounded">
        <div className="form-group position-relative">
          <input
            disabled={loading}
            className={`form-control position-relative`}
            type="date"
            required
            name="date"
            id="date"
            value={date}
            onChange={e => setDate(e.target.value)}
          />
        </div>
        <div className="text-center w-100 text-muted"><small> &mdash; DATOS DEL PROFESIONAL &mdash;</small></div>
        <div className="d-flex">
          <div className="flex-fill">
            <div className="form-group position-relative">
              <input
                disabled={loading}
                autoFocus
                className={`form-control position-relative${highlight && !practitioner.name ? ' is-invalid' : ''}`}
                type="text"
                required
                name="practitionerName"
                id="practitionerName"
                value={practitioner.name}
                onChange={e => setPractitioner({ ...practitioner, name: e.target.value })}
              />
              <label htmlFor="practitionerName" className={`font-weight-light position-absolute text-${highlight && !practitioner.name ? 'danger' : 'muted'}`}>Medico/a</label>
            </div>
          </div>
          <div>
            <div className="form-group ml-2">
              <select
                disabled={loading}
                name="practitionerPrefix"
                id="practitionerPrefix"
                value={practitioner.prefix}
                className={`form-control position-relative`}
                onChange={e => setPractitioner({ ...practitioner, prefix: e.target.value })}
              >
                <option value="Dra.">Dra.</option>
                <option value="Dr.">Dr.</option>
              </select>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="flex-fill">
            <div className="form-group position-relative">
              <input
                disabled={loading}
                className={`form-control position-relative${highlight && !member.number ? ' is-invalid' : ''}`}
                type="text"
                inputMode="numeric"
                pattern="\d*"
                required
                name="memberNumber"
                id="memberNumber"
                value={member.number}
                onChange={e => setMember({ ...member, number: e.target.value })}
              />
              <label htmlFor="memberNumber" className={`font-weight-light position-absolute text-${highlight && !member.number ? 'danger' : 'muted'}`}>Matricula</label>
            </div>
          </div>
          <div>
            <div className="form-group ml-2">
              <select
                disabled={loading}
                name="memberType"
                id="memberType"
                value={member.type}
                className={`form-control position-relative`}
                onChange={e => setMember({ ...member, type: e.target.value })}
              >
                <option value="M.N.">M.N.</option>
                <option value="M.P.">M.P.</option>
              </select>
            </div>
          </div>
        </div>

        {member.type === 'M.P.' && <div className="form-group">
          <select
            disabled={loading}
            name="memberState"
            id="memberState"
            value={member.state}
            className={`form-control position-relative`}
            onChange={e => setMember({ ...member, state: e.target.value })}
          >
            <option value="">Provincia de la matricula</option>
            <option value="buenos-aires">Buenos Aires</option>
            <option value="cordoba">Cordoba</option>
            <option value="entre-rios">Entre Rios</option>
            <option value="la-pampa">La Pampa</option>
            <option value="mendoza">Mendoza</option>
            <option value="san-juan">San Juan</option>
            <option value="tucuman">Tucuman</option>
            <option value="la-rioja">La Rioja</option>
            <option value="rio-negro">Rio Negro</option>
            <option value="misiones">Misiones</option>
            <option value="santa-fe">Santa Fe</option>
            <option value="santa-cruz">Santa Cruz</option>
            <option value="san-luis">San Luis</option>
            <option value="corrientes">Corrientes</option>
            <option value="chubut">Chubut</option>
            <option value="catamarca">Catamarca</option>
            <option value="chaco">Chaco</option>
            <option value="santiago-del-estero">Santiago del Estero</option>
            <option value="jujuy">Jujuy</option>
            <option value="formosa">Formosa</option>
            <option value="salta">Salta</option>
            <option value="neuquen">Neuquen</option>
            <option value="tierra-del-fuego">Tierra del Fuego</option>
          </select>
        </div>}

        <div className="form-group position-relative">
          <input
            disabled={loading}
            className={`form-control position-relative${highlight && !practitioner.specialty ? ' is-invalid' : ''}`}
            type="text"
            required
            name="practitionerSpecialty"
            id="practitionerSpecialty"
            value={practitioner.specialty}
            onChange={e => setPractitioner({ ...practitioner, specialty: e.target.value })}
          />
          <label htmlFor="practitionerSpecialty" className={`font-weight-light position-absolute text-${highlight && !practitioner.specialty ? 'danger' : 'muted'}`}>Especialidad</label>
        </div>

        <div className="form-group position-relative">
          <input
            disabled={loading}
            className={`form-control position-relative${highlight && !practitioner.address ? ' is-invalid' : ''}`}
            type="text"
            required
            name="practitionerAddress"
            id="practitionerAddress"
            value={practitioner.address}
            onChange={e => setPractitioner({ ...practitioner, address: e.target.value })}
          />
          <label htmlFor="practitionerAddress" className={`font-weight-light position-absolute text-${highlight && !practitioner.address ? 'danger' : 'muted'}`}>Direccion</label>
        </div>

        <div className="form-group position-relative">
          <input
            disabled={loading}
            className={`form-control position-relative${highlight && !practitioner.phone ? ' is-invalid' : ''}`}
            type="text"
            inputMode="numeric"
            pattern="\d*"
            required
            name="practitionerPhone"
            id="practitionerPhone"
            value={practitioner.phone}
            onChange={e => setPractitioner({ ...practitioner, phone: e.target.value })}
          />
          <label htmlFor="practitionerPhone" className={`font-weight-light position-absolute text-${highlight && !practitioner.phone ? 'danger' : 'muted'}`}>Teléfono</label>
        </div>

        <div className="form-group position-relative">
          <input
            disabled={loading}
            className={`form-control position-relative${highlight && !practitioner.email ? ' is-invalid' : ''}`}
            type="email"
            inputMode="email"
            required
            name="practitionerEmail"
            id="practitionerEmail"
            value={practitioner.email}
            onChange={e => setPractitioner({ ...practitioner, email: e.target.value })}
          />
          <label htmlFor="practitionerPhone" className={`font-weight-light position-absolute text-${highlight && !practitioner.email ? 'danger' : 'muted'}`}>Email</label>
        </div>

        <div className="text-center w-100 text-muted"><small> &mdash; DATOS DEL PACIENTE &mdash;</small></div>

        <div className="form-group position-relative">
          <input
            disabled={loading}
            className={`form-control position-relative${highlight && !patient.name ? ' is-invalid' : ''}`}
            type="text"
            required
            name="patientName"
            id="patientName"
            value={patient.name}
            onChange={e => setPatient({ ...patient, name: e.target.value })}
          />
          <label htmlFor="patientName" className={`font-weight-light position-absolute text-${highlight && !patient.name ? 'danger' : 'muted'}`}>Nombre</label>
        </div>

        <div className="form-group position-relative">
          <input
            disabled={loading}
            className={`form-control position-relative${highlight && !patient.identification ? ' is-invalid' : ''}`}
            type="text"
            inputMode="numeric"
            pattern="\d*"
            required
            name="patientIdentification"
            id="patientIdentification"
            value={patient.identification}
            onChange={e => setPatient({ ...patient, identification: e.target.value })}
          />
          <label htmlFor="patientIdentification" className={`font-weight-light position-absolute text-${highlight && !patient.identification ? 'danger' : 'muted'}`}>Documento</label>
        </div>

        <div className="form-group position-relative">
          <input
            disabled={loading}
            className={`form-control position-relative`}
            type="text"
            required
            name="insuranceName"
            id="insuranceName"
            value={insurance.name}
            onChange={e => setInsurance({ ...insurance, name: e.target.value })}
          />
          <label htmlFor="insuranceName" className={`font-weight-light position-absolute`}>Cobertura</label>
        </div>

        <div className="form-group position-relative">
          <input
            disabled={loading}
            className={`form-control position-relative`}
            type="text"
            required
            name="insuranceName"
            id="insuranceName"
            value={insurance.plan}
            onChange={e => setInsurance({ ...insurance, plan: e.target.value })}
          />
          <label htmlFor="insuranceName" className="text-muted font-weight-light position-absolute">Plan</label>
        </div>

        <div className="form-group position-relative">
          <input
            disabled={loading}
            className={`form-control position-relative`}
            type="text"
            required
            name="insuranceID"
            id="insuranceID"
            value={insurance.id}
            onChange={e => setInsurance({ ...insurance, id: e.target.value })}
          />
          <label htmlFor="insuranceID" className="text-muted font-weight-light position-absolute">Nro. Afiliado</label>
        </div>

        <div className="form-group">
          <Textarea
            disabled={loading}
            placeholder="Texto libre. Orden / Estudio / Diagnóstico"
            className="form-control"
            value={content}
            onChange={e => setContent(e.target.value)}
            rows="1"
          />
        </div>

        <div className="form-group">
          <AsyncTypeahead
            disabled={loading}
            ref={$search}
            isLoading={false}
            newSelectionPrefix=''
            emptyLabel='No hay resultados.'
            id="search"
            placeholder="Buscador de medicamentos"
            labelKey={option => `${option.label}`}
            renderMenuItemChildren={option => <div key={option.id}>
              <strong>{option.items[0]}</strong> - <span>{option.items[1]}</span> - <span>{option.items[2]}</span>
            </div>}
            filterBy={options => options}
            promptText={'Ingrese al menos 3 caracteres'}
            searchText={'Buscando...'}
            onSearch={(query) => {
              fetch(`https://api.drapp.la/vademecums/ar?q=${query}`)
                .then(res => res.json())
                .then(array => {
                  const options = array.map(item => ({
                    ...item,
                    label: `${item.drug} - ${item.product} - ${item.presentation}`,
                    id: item.product_code,
                    drugCode: item.drug_code,
                    quantity: '1',
                    items: [item.drug, item.product, item.presentation]
                  }))

                  let position = 1
                  if (window.gtag) window.gtag('event', 'view_item_list', {
                    items: array.map(item => ({
                      id: item.drug_code.toString(),
                      name: `${item.drug} - ${item.product} - ${item.presentation}`,
                      list_name: "Buscador",
                      variant: item.product_code,
                      list_position: position++,
                      quantity: item.quantity ?? '1'
                    }))
                  })
                  setOptions(options)
                })
              }
            }
            onChange={(items) => {
              setSelected([
                ...selected,
                ...items
              ].filter(p => p))
              $search.current.getInstance().clear()
              if (window.gtag) {
                for (let i = 0; i < items.length; i += 1) {
                  const item = items[i]
                  window.gtag('event', 'add_to_cart', {
                    items: [
                      {
                        id: item.drugCode,
                        name: item.label,
                        list_name: "Buscador",
                        variant: item.id,
                        list_position: i + 1,
                        quantity: item.quantity || '1'
                      }
                    ]
                  })
                }
              }
            }}
            options={options}
          />
        </div>

        {selected.length > 0 && <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Un.</th>
                <th>Producto</th>
                <th data-tip="Marca si es un tratamiento prolongado">TP</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {selected.map(item => <tr key={item.label}>
                <td className="align-middle p-0 bg-light" style={{ minWidth: '65px', maxWidth: '65px' }} >
                  <input
                    type="number"
                    pattern="\d*"
                    value={item.quantity || ''}
                    onChange={e => updateQ(item, e.target.value)}
                    className="form-control form-control-sm m-0 text-center border-0 bg-transparent"
                  />
                </td>
                <td className="align-middle">
                  <div>
                    <strong>{item.items[0]}</strong>{' - '}
                    <span>{item.items[1]}</span>{' - '}
                    <span>{item.items[2]}</span>
                  </div>
                </td>
                <td data-tip="Marca si es un tratamiento prolongado" className="align-middle p-0 bg-light text-center" style={{ minWidth: '50px', maxWidth: '50px' }} >
                  <input
                    className="form-check-input position-static m-0"
                    type="checkbox"
                    checked={item.type === 'long-term'}
                    onChange={() => toggleType(item)}
                  />
                </td>
                <td className="align-middle">
                  <button className="p-0 m-0 align-baseline btn btn-sm btn-link text-danger" onClick={() => remove(item)}>Sacar</button>
                </td>
              </tr>)}
            </tbody>
          </table>
        </div>}

        <div className="form-group">
          <Textarea
            disabled={loading}
            placeholder="Indicaciones"
            className="form-control"
            value={indications}
            onChange={e => setIndications(e.target.value)}
            rows="1"
          />
        </div>

        <label>
          <input type="checkbox" checked={!!checkSignature} onChange={() => setCheckSignature(!checkSignature)} /> Tengo firma digital
        </label>


        {checkSignature && (
          <div className="mb-3">
            <div className="custom-file mb-3">
              <input
                type="file"
                className="custom-file-input"
                id="certificateFile"
                name="file"
                onChange={onChangeCertificate}
                accept=".p12,.pem,.crt,.cert,.cer,.x509,application/x-pkcs12"
              />
              {certificate.name ? (
                <label className="custom-file-label" htmlFor="certificateFile">Archivo: <strong>{certificate.name}</strong></label>
              ) : (
                <label className="custom-file-label" htmlFor="certificateFile">Seleccione certificado P12 o X509</label>
              )}
            </div>

            <div className="form-group position-relative">
              <input
                disabled={loading}
                className={`form-control position-relative`}
                type="text"
                required
                name="phrase"
                id="certificatePhrase"
                value={certificate.phrase || ''}
                onChange={onChangeCertificate}
              />
              <label htmlFor="certificatePhrase" className={`font-weight-light position-absolute text-muted`}>Clave del certificado</label>
            </div>
            <small className="text-muted form-text mt-n3">Si el certificado no tiene clave deje en blanco este campo</small>
          </div>
        )}

        {disabled ? (
          <div className="text-center alert alert-warning">Para generar la receta tienen que estar completos todos los datos del profesional, el nombre y documento del paciente</div>
        ) : (
          <div className="form-group text-center">
            {!link ? (
              <button className="btn btn-block btn-lg btn-primary" disabled={loading} onClick={download}>{loading ? 'Descargando' : 'Descargar'}</button>
            ) : (
              <a href={link} className="btn btn-block btn-lg btn-success" rel="noopener noreferrer" target="_blank" download="receta.pdf">Link de descarga</a>
            )}

            {step === 1 && <button className="btn btn-block btn-lg btn-primary" onClick={() => setStep(2)}>Enviar por email</button>}
            {step === 2 && <div className="d-flex mt-2">
              <div className="flex-fill mr-2">
                <input type="email" disabled={loading} value={patient.email} onChange={e => setPatient({ ...patient, email: e.target.value })} className="form-control form-control-lg" placeholder="Email del paciente" />
              </div>
              <div>
                <button disabled={!isEmail(patient.email) || loading} className="btn btn-block btn-lg btn-primary" onClick={sendEmail}>{loading ? 'Enviando' : 'Enviar'}</button>
              </div>
            </div>}
          </div>
        )}
      </div>
    </div>
  )
}

export default App
